<template>
  <div id="landingpage">
    <GrapesJSRender :components="components"/>
  </div>
</template>
<script>

import GrapesJSRender from '@/components/GrapesJs/GrapesJSRender'

export default {
  data(){
    return {

    }
  },
  components: {
    GrapesJSRender
  },
  computed: {
    components(){
      return localStorage.getItem('gjs-components')
    }
  }
}
</script>
